import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { helpers, withBasic } from '@common'
import Icon from '@pc/components/Icon'

import { actionCreator } from '../../store'

// import icon_voucher from '@pc/assets/img/icon_voucher.png'
import styles from './style.m.scss'
import { COUPON_MODAL_FLAG, COUPON_TYPE } from '@pc/common/macro'
import Logger from '@pc/config/logger'
import loggerConfig from '../../logger.config'

const className = helpers.classNames.react(styles)

@withBasic
@connect(
  (state) => ({
    couponAmount: state.getIn(['orderDetails', 'orderAmount']),
    curCouponId: state.getIn(['orderDetail', 'curCouponId']),
    availableCouponList: state.getIn([
      'orderDetail',
      'coupons',
      'availableCouponList',
    ]), // 可用优惠券
    discountAmount: state.getIn(['orderDetail', 'coupons', 'discountAmount']),
  }),
  (dispatch) => ({
    showCouponList(couponId) {
      dispatch(actionCreator.goSetCouponModalFlag(COUPON_MODAL_FLAG.COUPON))
      // 打开弹窗的时候 记录原先外面现实的优惠券id
      dispatch(actionCreator.goSetPrevCouponId(couponId))
      dispatch(actionCreator.goToggleBasicPopup(true, 'couponList'))
      const saLogger = Logger.getInstance()
      saLogger.onPageClick(loggerConfig.click_coupon)
      saLogger.onPageClick(loggerConfig.coupon_popup)
    },
  }),
)
export default class CouponBar extends PureComponent {
  getContent = () => {
    const {
      curCouponId,
      availableCouponList,
      formatPrice,
      currencyCode,
      translate,
      discountAmount,
    } = this.props

    // 选择了优惠券
    if (curCouponId) {
      const curCoupon = availableCouponList
        ?.toJS()
        .find((coupon) => coupon.couponId === curCouponId)
      if (
        curCoupon &&
        (curCoupon.couponType === COUPON_TYPE.COUPON_OPENPAY_INTEREST ||
          curCoupon.couponType === COUPON_TYPE.COUPON_INTEREST_RATE)
      ) {
        return <span {...className('coupon_last_child')}>{curCoupon.name}</span>
      } else if (discountAmount) {
        return (
          <span {...className('coupon_last_child')}>
            -{currencyCode}
            {formatPrice(discountAmount)}
          </span>
        )
      } else {
        return null
      }
    } else {
      // 有可用优惠券
      if (availableCouponList && availableCouponList.size) {
        return (
          <span {...className('coupon_last_child')}>
            {translate('${amount}张', { amount: availableCouponList.size })}
          </span>
        )
      } else {
        // 没有可用
        return null
      }
    }
  }

  render() {
    const { translate, showCouponList, platform, curCouponId } = this.props

    return (
      <div
        {...className({
          'coupon-bar-wrapper': platform !== 'pc',
          'coupon-bar-wrapper-pc': platform === 'pc',
        })}
        onClick={() => showCouponList(curCouponId)}
      >
        {/* <Icon
          {...className('icon_voucher')}
          icontype="image"
          src={icon_voucher}
        /> */}
        <p>
          <span>{translate('优惠券')}</span>
          {this.getContent()}
        </p>
        <Icon
          {...className('icon-right')}
          icontype="svg"
          name="arrow-right-light"
        />
      </div>
    )
  }
}
