import React, { PureComponent } from 'react'
import { Modal } from 'antd-mobile'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { globalActionCreator } from '@pc/common/globalActions'
import { helpers, withBasic } from '@common'
import { PasswordInput } from '../../../login/components/InputFields'
import { ModalTitle } from '@pc/components/ModalTitle'
import Logger, * as elementId from '@pc/config/logger'
import { actionCreator } from '../../store'
import { Translate } from '@pc/config/translate'
import { ForgetPassword } from '@pc/components/ForgetPassword'
import styles from './style.m.scss'
import loggerConfig from '@pc/views/orderDetails/logger.config'
import BizTracker from '@pc/common/tracker/bizTracker'

const className = helpers.classNames.react(styles)
const { get } = helpers

@withBasic
class LoginPasswordForm extends PureComponent {
  constructor() {
    super()
    this.state = {
      loginPassword: '',
      focusStatus: false,
      inputError: false, // 记录input输入错误 没有任何用 只是为了更新样式 坑
    }
    this.controls = []
    this.beforeSubmit = this.beforeSubmit.bind(this)
    this.saLogger = Logger.getInstance()
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.showLoginPasswordDialog &&
      this.props.showLoginPasswordDialog
    ) {
      BizTracker.expose({
        cn: 21,
      })
      this.saLogger.onPageClick(loggerConfig.payment_popup)
    }
  }

  //保存控件实例到数组中
  register = (instance) => {
    this.controls.push(instance)
  }

  //收集控件value值保存到表单state中
  collect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  beforeSubmit = () => {
    const {
      setLoginPwdErrorText,
      showSmallPayController,
      setShowQuickPayTipInPasswordModal,
    } = this.props

    //提交表单前，每一个控件应该通过数据校验
    let result, errMsg

    result = this.controls.every((elem) => {
      if (elem.validate(elem.state.value) === false) {
        return true
      } else {
        errMsg = elem.validate(elem.state.value)
        setLoginPwdErrorText(errMsg)
        // 设置密码弹窗里面的小额免密弹窗 只有满足小额免密的情况下才需要执行这个
        showSmallPayController && setShowQuickPayTipInPasswordModal()
        return false
      }
    })

    return result
  }

  submit = () => {
    const { loginPassword } = this.state

    if (this.beforeSubmit()) {
      this.props.onSuccess(loginPassword)
    }
  }

  //如果控件被销毁，则要重置数组
  removeController = (name) => {
    if (!name) return

    this.controls = this.controls.filter((elem) => {
      if (elem.name !== name) return elem
    })
  }

  // 通过ref 自动聚焦
  onRef = (inputRef) => {
    const inputDom = get(inputRef, ['inputRef', 'current'])
    inputDom && inputDom.focus()
  }

  inputError = (errMsg) => {
    this.state.inputError || this.setState({ inputError: true })
  }

  inputSuccess = () => {
    this.state.inputError &&
      this.setState({
        inputError: false,
      })
  }

  render() {
    const {
      showLoginPasswordDialog,
      translate,
      setLoginPwdErrorText,
      toggleLoginPasswordDialog,
      platform,
      onForgetPwdClick,
      clickQuickPayTip,
      showQuickPayTipInPasswordModal,
    } = this.props
    const { loginPwdErrorText } = this.props
    const { focusStatus, inputError } = this.state

    return (
      <form {...className('login-password-form')}>
        <Modal
          {...className({ pc_modal: platform === 'pc' })}
          visible={showLoginPasswordDialog}
          transparent
          maskClosable={false}
          transparent
          {...className('login_password_modal')}
          wrapClassName={
            className('login_password_wrap_modal', { focus: focusStatus })
              .className
          }
          title={
            <ModalTitle
              borderBottom={false}
              title={translate('请输入您的登录密码')}
              onClick={() => {
                setLoginPwdErrorText('')
                toggleLoginPasswordDialog(false)
                this.saLogger.onPageClick(loggerConfig.password_close)
                this.removeController('loginPassword')
              }}
            />
          }
          footer={[
            {
              text: translate('提交'),
              onPress: () => {
                this.saLogger.onPageClick(loggerConfig.password_confirm)
                BizTracker.click({ cn: 23 })
                debounce(this.submit, 300)()
              },
            },
          ]}
        >
          <div {...className('login-password-content')}>
            <PasswordInput
              type="password"
              name="loginPassword"
              placeholder={translate('请输入密码（8-16位字符）')}
              onRegister={this.register}
              onControl={this.collect}
              errorText={loginPwdErrorText}
              validation={{ type: 'password' }}
              onFocus={() => {
                setLoginPwdErrorText('')
                this.setState({ focusStatus: true })
                this.saLogger.onPageClick(loggerConfig.click_password_box)
              }}
              onBlur={() => {
                this.setState({ focusStatus: false })
                this.saLogger.onPageClick(loggerConfig.input_password)
              }}
              required
              hasClearIcon
              page="orderDetail"
              onRef={this.onRef}
              onError={this.inputError}
              onSuccess={this.inputSuccess}
              {...className('password_input')}
              onChange={(ic) => {
                BizTracker.input({
                  key: 'order-detail-login',
                  cn: 22,
                  ic,
                })
              }}
            />
          </div>
          <div
            {...className('forget_password', {
              has_error: inputError || loginPwdErrorText,
            })}
            onClick={() => {
              this.saLogger.onPageClick(loggerConfig.forget_password)
              onForgetPwdClick()
            }}
          >
            {translate('忘记密码？')}
          </div>
          {showQuickPayTipInPasswordModal ? (
            <p {...className('quick_pay_tip')}>
              <span>{translate('忘记密码了？')}</span>
              <span onClick={clickQuickPayTip} {...className('jump_tip')}>
                {translate('请试试Aku QuickPay')}
              </span>
            </p>
          ) : null}
        </Modal>
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  showLoginPasswordDialog: state.getIn([
    'orderDetail',
    'showLoginPasswordDialog',
  ]),
  loginPwdErrorText: state.getIn(['orderDetail', 'loginPwdErrorText']),
  loginPwdErrorText: state.getIn(['orderDetail', 'loginPwdErrorText']),
  showSmallPayController: state.getIn([
    'orderDetail',
    'showSmallPayController',
  ]),
  showQuickPayTipInPasswordModal: state.getIn([
    'orderDetail',
    'showQuickPayTipInPasswordModal',
  ]),
})

const mapDispatchToProps = (dispatch) => ({
  onForgetPwdClick() {
    const translate = Translate.getInstance().translate
    dispatch(actionCreator.goSetForgetPasswordSMS())

    dispatch(
      globalActionCreator.toggleAlertDialog(true, {
        title: translate('如何找回密码?'),
        content: <ForgetPassword translate={translate} />,
        footer: [],
        hideFooter: true,
      }),
    )
  },
  setLoginPwdErrorText(errText) {
    dispatch(actionCreator.goSetLoginPwdErrorText(errText))
  },
  toggleLoginPasswordDialog(show) {
    dispatch(actionCreator.toggleLoginPasswordDialog(show))
  },
  clickQuickPayTip() {
    dispatch(actionCreator.goToggleQuickPaymentReminderHasOpen(false))
    dispatch(actionCreator.goToggleQuickPaymentReminderVisible(true))
  },
  setShowQuickPayTipInPasswordModal() {
    dispatch(actionCreator.goSetShowQuickPayTipInPasswordModal(true))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPasswordForm)
